<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="$t('order.operation.Phone_number')"
            :rules="{ required:true, min:10, max:10, numeric:true }"
          >
            <!-- Numéro de la commande chez le fournisseur-->
            <v-text-field
              v-model="submitForm.no_telephone"
              outlined
              maxlength="10"
              placeholder="XXXXXXXXXX"
              :label="$t('order.operation.Phone_number')"
              :error-messages="errors"
              :success="valid"
              required
            />
          </validation-provider>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <v-btn
      :disabled="invalid"
      class="mt-3"
      color="success"
      @click="handleSubmit(submit)"
    >
      {{ $t('Validate') }}
    </v-btn>
  </validation-observer>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import ContratsService from '@/services/01Cell/ContratsService'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'
  import Swal from 'sweetalert2'

  export default {
    name: 'ClientCommandeDemoUqamDialogMissingNoPhone',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        submitForm: {},
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterFournisseur: 'getFournisseur',
      }),
    },

    methods: {
      getData (data) {
        this.submitForm = data
      },
      async submit () {
        const phoneNumber = ` (${this.submitForm.no_telephone.slice(0, 3)}) ${this.submitForm.no_telephone.slice(3, 6)}-${this.submitForm.no_telephone.slice(6)}`
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('order.operation.Phone_number'),
          html: `${this.$i18n.t('contract.swal.text.phone_number_is_good')} ? <br> <h1>${phoneNumber}</h1>`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Confirm'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            try {
              // Close Dialog
              this.$emit('close-dialog')

              // Start Spinner
              this.$loading.show()

              let contratId
              let message
              // On regarde si il y a déjà un contrat associé au numéro de téléphone
              const contracts = await ContratsService.search(this.submitForm.no_telephone)
              const findContract = contracts.find(item => item.no === this.submitForm.no_telephone)
              // Si il y a déjà un contrat associé au numéro de téléphone, on mets à jour le contrat
              if (findContract) {
                // Ce numéro EXISTE et est ACTIF dans le système.
                if (findContract.statut !== 4 && findContract.statut !== 2) {
                  // Stop Spinner
                  this.$loading.hide()

                  // Swal
                  Swal.fire(
                    'Attention',
                    `${this.$i18n.t('order.swal.text.This_number_already_active_in_the_system_You_cannot_use_it')}`,
                    'error',
                  )

                  // On arrête la function
                  return
                } else {
                  // Id du contrat pour mettre dans la commande
                  contratId = findContract.id
                  message = `${this.$i18n.t('contract.dialog.The_contract')} <b>${this.submitForm.no_telephone}</b> ${this.$i18n.t('contract.dialog.has_been_updated')}`

                  // Update du contrat
                  const updateContrat = {
                    statut: 1, // Actif
                    clientId: this.submitForm.clientId,
                    code_facturation: this.submitForm.code_facturation,
                    forfait_id: this.submitForm.forfait,
                    description: this.submitForm.contrat_label,
                    type: this.submitForm.mode_paiement,
                    InscriptionPaiementId: this.submitForm.InscriptionPaiementId,
                  }

                  await ContratsService.update(updateContrat, findContract.id)
                }
              } else {
                // Il n'y a pas de contrat avec le numéro de téléphone. On crée le contrat
                const newContrat = {
                  no: this.submitForm.no_telephone,
                  statut: 1,
                  fournisseur: this.parameterFournisseur || 'telus',
                  forfait_id: this.submitForm.forfait,
                  description: this.submitForm.contrat_label,
                  type: this.submitForm.mode_paiement,
                  code_facturation: this.submitForm.code_facturation,
                  paiement_automatique: this.submitForm.paiement_automatique,
                  ClientId: this.submitForm.ClientId,
                  InscriptionPaiementId: this.submitForm.InscriptionPaiementId,
                }
                const contrat = await ContratsService.save(newContrat)
                // Id du contrat pour mettre dans la commande
                contratId = contrat.id
                // Message Swal
                message = `${this.$i18n.t('contract.dialog.The_contract')} <b>(${this.submitForm.no_telephone.slice(0, 3)}) ${this.submitForm.no_telephone.slice(3, 6)}-${this.submitForm.no_telephone.slice(6)}</b> ${this.$i18n.t('contract.dialog.has_been_created')}`
              }

              // Commande
              const commande = {
                no_telephone: this.submitForm.no_telephone,
                statut: 3,
                etape: 5,
                ContratId: contratId,
              }

              const response = await CommandesService.update(commande, this.submitForm.id)

              // Ouvre un dialog pour informer le client
              this.$dialog.show({ message: message, print: false, html: true, iconState: true, iconColor: 'success', iconName: 'mdi-check-circle' })

              // On update la liste dans le composant 'OperationCommande.vue'
              EventBus.$emit('update-commande', response)
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
    },
  }
</script>
