<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="$t('order.operation.Device_cost_before_tx')"
            :rules="{ required:true, min_value:1 }"
          >
            <!-- Coût de l'appareil-->
            <v-text-field
              v-model="submitForm.cout_at"
              type="number"
              :label="$t('order.operation.Device_cost_before_tx')"
              :error-messages="errors"
              :success="valid"
              required
            >
              <v-icon
                slot="prepend"
                color="green"
              >
                mdi-currency-usd
              </v-icon>
            </v-text-field>
          </validation-provider>

          <!-- TVQ-->
          <v-text-field
            v-model="submitForm.cout_tvq"
            disabled
            label="TVQ"
          >
            <v-icon
              slot="prepend"
              color="green"
            >
              mdi-currency-usd
            </v-icon>
          </v-text-field>

          <!-- TPS-->
          <v-text-field
            v-model="submitForm.cout_tps"
            disabled
            label="TPS"
          >
            <v-icon
              slot="prepend"
              color="green"
            >
              mdi-currency-usd
            </v-icon>
          </v-text-field>

          <!-- Coût total-->
          <v-text-field
            v-model="submitForm.cout_total"
            disabled
            :label="$t('order.operation.Total_cost')"
          >
            <v-icon
              slot="prepend"
              color="green"
            >
              mdi-currency-usd
            </v-icon>
          </v-text-field>

          <!-- Coût ristourné-->
          <v-text-field
            v-model="submitForm.cout_ristourne"
            disabled
            :label="$t('order.operation.Discounted_cost')"
          >
            <v-icon
              slot="prepend"
              color="green"
            >
              mdi-currency-usd
            </v-icon>
          </v-text-field>

          <!-- Coût facturé-->
          <v-text-field
            v-model="submitForm.cout_facture"
            disabled
            :label="$t('order.operation.Invoiced_cost')"
          >
            <v-icon
              slot="prepend"
              color="green"
            >
              mdi-currency-usd
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <v-btn
      :disabled="invalid"
      class="mt-3"
      color="success"
      @click="handleSubmit(submit)"
    >
      {{ $t('Validate') }}
    </v-btn>
    <!--  Sans frais-->
    <v-btn
      class="mt-3"
      color="warning"
      @click="sansFrais"
    >
      Sans frais
    </v-btn>
  </validation-observer>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  // Mixins
  import { orders } from '@/mixins/Client/Order/orders'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'ClientCommandeDemoUqamDialogMissingCost',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [orders],
    data () {
      return {
        submitForm: {},
        tauxRistourneTPS: null,
        tauxRistourneTVQ: null,
        tauxRistourne: null,
        prixSimCard: null,
      }
    },

    computed: {
    },

    watch: {
      'submitForm.cout_at' (val) {
        const cost = val || 0
        this.addCost(cost)
      },
    },

    mounted () {
      // Taxes et prix pour la SIM card
      this.getParameters()
    },

    methods: {
      getData (data) {
        this.submitForm = data
      },
      sansFrais () {
        this.submitForm.cout_at = 0
        this.submitForm.sans_frais = true
        // A cause du watch, on attends que le watch mette les valeurs à 0 dollars. Fonction 'this.addCost'
        this.$nextTick(() => {
          this.update()
        })
      },
      async submit () {
        // On ajoute les coût si ce n'est pas déjà fait
        // this.addCost()
        this.submitForm.sans_frais = false
        this.update()
      },
      async update () {
        // Close Dialog
        this.$emit('close-dialog')

        // Start Spinner
        this.$loading.show()
        try {
          // On ajoute les coût si ce n'est pas déjà fait
          // this.addCost()
          this.submitForm.etape = 2
          const response = await CommandesService.update(this.submitForm, this.submitForm.id)
          // // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
